import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Nav, Footer, Pagination, Spiner } from '../../components/';
import header_img from '../../image/header-logo.png';

const Shlopanci = () => {
    const [filterData, setFilterData] = useState(null);
    const [structuredData, setStructuredData] = useState(null);
    const [search, setSearch] = useState("");
    const { posts } = useSelector(state => state.post);
    const type = "Шльопанці";
    const [loading, setLoading] = useState(true);
    const [selectSort, setSelectSort] = useState("default");
    const [selectSize, setSelectSize] = useState("default");
    const [selectSeason, setSelectSeason] = useState("default");
    const [selectColor, setSelectColor] = useState("default");
    const [size, setSize] = useState(null);
    const [colors, setColors] = useState(null);
     const [hasSizes, setHasSizes] = useState(false);
    const [hasColors, setHasColors] = useState(false);
    const [hasSeasons, setHasSeasons] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
         if (posts.data) {
           const sizes = [];
          const colors = [];
            let seasons = new Set();

            posts.data.filter(item => item.type === type).forEach(post => {
                post.sizes.forEach(size => {
                  if (!sizes.includes(size)) {
                      sizes.push(size);
                  }
              });
                if (!colors.includes(post.color)) {
                    colors.push(post.color);
                }
                 seasons.add(post.season);
            });
            
            setHasSizes(sizes.length > 0);
            setHasColors(colors.length > 0);
            setHasSeasons(seasons.size > 0)

            sizes.sort((a, b) => a - b);
            colors.sort();
            setSize(sizes);
            setColors(colors);
            applyFilter();
          setLoading(false);
        }
    }, [posts.data, selectSort, selectSize, selectSeason, selectColor, type]);

    const applyFilter = () => {
         let data = posts.data.filter(elem => elem.type === type);

       if (selectSize !== "default" && hasSizes) {
            data = data.filter(elem => elem.sizes.map(Number).includes(parseInt(selectSize)));
        }
         if (selectSeason !== "default" && hasSeasons) {
            data = data.filter(elem => elem.season === selectSeason);
        }
        if (selectColor !== "default" && hasColors) {
            data = data.filter(elem => elem.color === selectColor);
        }


        if (selectSort !== "default") {
            if (selectSort === 'popular') {
                data = [...data].sort((elem, nextelem) => nextelem.popular - elem.popular);
            }
            if (selectSort === 'unpopular') {
                data = [...data].sort((elem, nextelem) => elem.popular - nextelem.popular);
            }
            if (selectSort === 'rich') {
                data = [...data].sort((elem, nextelem) => nextelem.price - elem.price);
            }
            if (selectSort === 'poor') {
                data = [...data].sort((elem, nextelem) => elem.price - nextelem.price);
            }
        }
        setFilterData(data);
        generateStructuredData(data);
    };

       const generateStructuredData = (data) => {
        if (!data) {
            return;
        }
         const structuredData = data.map(product => ({
            "@context": "https://schema.org",
            "@type": "Product",
            "name": product.title,
            "image": product.images.map(image => `https://api.shoedream.com.ua/uploads/${image}`),
            "description": product.description.replace(/<\/?[^>]+(>|$)/g, ""),
            "brand": {
                "@type": "Brand",
                "name": product.brand
            },
            "sku": product.article,
            "material": product.material_top,
            "color": product.color,
            "priceCurrency": "UAH",
            "price": product.discount ? product.new_price : product.price,
            "offers": {
                "@type": "Offer",
                "url": `https://api.shoedream.com.ua/posts/${product.slug}`,
                "priceCurrency": "UAH",
                "price": product.discount ? product.new_price : product.price,
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock"
            },
            "additionalType": "https://schema.org/Shoe",
            "size": product.sizes,
            "season": product.season,
            "countryOfOrigin": product.country,
            "discount": product.discount
        }));

        setStructuredData(structuredData);
    };

    const searchProduct = (value) => {
         if (value) {
           let data = posts.data.filter(item => item.type === type);
            data = [...data].filter(elem => elem.title.toLowerCase().includes(value.toLowerCase()));

            if (data) {
                setFilterData(data);
                generateStructuredData(data);
            }
        } else {
            setFilterData(null);
            setStructuredData(null);
        }
    };

    const handleSearchInputChange = (event) => {
        setSearch(event.target.value);
        searchProduct(event.target.value);
    };

    const handleSearchButtonClick = () => {
        searchProduct(search);
    };

    const deleteFilter = () => {
        setSelectSort("default");
        setSelectSize("default");
        setSelectSeason("default");
        setSelectColor("default");
        setSearch("");
    };

    return (
        <><Helmet>
        <title>Купити Шльопанці Жіночі в Україні | Взуттєва Мрія - Пляжні, Шкіряні, Гумові</title>
        <meta name="description" content="Широкий вибір жіночих шльопанців в інтернет-магазині Взуттєва Мрія. Купуйте стильні пляжні, шкіряні та гумові шльопанці за вигідними цінами з доставкою по Україні." />
        <meta name="keywords" content="шльопанці, купити шльопанці, шльопанці жіночі, шльопанці пляжні, шльопанці шкіряні, шльопанці гумові, шльопанці ціна, шльопанці Київ, шльопанці Україна, інтернет-магазин взуття, взуття жіноче" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Купити Шльопанці Жіночі в Україні | Взуттєва Мрія - Пляжні, Шкіряні, Гумові" />
        <meta property="og:description" content="Широкий вибір жіночих шльопанців в інтернет-магазині Взуттєва Мрія. Купуйте стильні пляжні, шкіряні та гумові шльопанці за вигідними цінами з доставкою по Україні." />
            {structuredData && (
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            )}
    </Helmet>
    <Nav />
    <header className="header">
        <div className="container">
            <div className="header__wrapper">
                <div className="header__content">
                    <h1 className="header__title title">Купити Шльопанці Жіночі</h1>
                     <p className="header__text">Зручні та стильні шльопанці з різних матеріалів для літнього відпочинку та повсякденного використання. Оберіть свою ідеальну пару.</p>
                </div>
                        <img src={header_img} alt="header" className="header__img" loading="lazy"/>
                    </div>
                </div>
            </header>
            <div className="goods">
                <div className="container">
                   <div className="filter">
                       <div className="filter__top">
                           <select name="sort" className="filter__sort" onChange={(event) => setSelectSort(event.target.value)} value={selectSort}>
                                <option value="default" disable="false" hidden>Сортувати за</option>
                                <option value="popular">Спочатку популярні</option>
                                <option value="unpopular">Спочатку не популярні</option>
                                <option value="rich">Спочатку дорогі</option>
                                <option value="poor">Спочатку дешеві</option>
                            </select>
                             {hasSizes && <select name="size" className="filter__size" onChange={(event) => setSelectSize(event.target.value)} value={selectSize}>
                                    <option value="default" disable="false" hidden>Розмір</option>
                                    {
                                        size.map((elem, index) => (
                                            <option key={index} value={elem}>{elem}</option>
                                        ))
                                    }
                                </select>}
                            {hasSeasons && <select name="season" className="filter__season" onChange={(event) => setSelectSeason(event.target.value)} value={selectSeason}>
                                    <option value="default" disable="false" hidden>Сезон</option>
                                    <option value="summer">Літо</option>
                                    <option value="winter">Зима</option>
                                    <option value="spring">Весна-осінь</option>
                                </select>}
                           {hasColors &&  <select name="color" className="filter__color" onChange={(event) => setSelectColor(event.target.value)} value={selectColor}>
                                    <option value="default" disable="false" hidden>Колір</option>
                                    {
                                        colors.map((elem, index) => (
                                            <option key={index} value={elem}>{elem}</option>
                                        ))
                                    }
                                </select>}
                        </div>
                        <div className="filter__bottom">
                           <button className="filter__delete" onClick={deleteFilter}>Скинути</button>
                            <div className="filter__search">
                                <button className="filter__search-btn" onClick={handleSearchButtonClick}></button>
                                <input type="text" className="filter__search-input" placeholder='Пошук' value={search} onChange={handleSearchInputChange} />
                            </div>
                        </div>
                    </div>
                     {loading ? <Spiner /> :
                       <Pagination items={filterData}
                            renderImage={(src) => (
                                <img src={src} alt="Product" loading="lazy" />
                            )}
                        />
                      }
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Shlopanci;