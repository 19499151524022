import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { decodeToken } from "react-jwt";
import { useDispatch } from "react-redux";

import './App.scss';
import { Home, Shop, ProductCart, Basket, Favourite, Auth, Account, Guarantee, Exchange, AboutUs, Contacts, ReturnPolicy, Payment } from './pages';
import { NewEdit, Search, Client, Order } from './components';
import { fetchAuthMe } from './redux/slices/auth';
import { fetchAllPosts } from './redux/slices/post';

import Botilioni from './pages/Botilioni';
import Botforti from './pages/Botforti'; // Імпорт Botforti
import Krosivki from './pages/Krosivki'; 
import Mokasini from './pages/Mokasini';
import Napivchoboti from './pages/Napivchoboti';
import Sliponi from './pages/Sliponi';
import Tufli from './pages/Tufli';
import Chereviki from './pages/Chereviki';
import Choboti from './pages/Choboti';
import Shlopanci from './pages/Shlopanci';

function App() {
    const dispatch = useDispatch();
    const [token, setToken] = useState();
    const role = token ? decodeToken(token).role : 'client';

    if (window.localStorage.getItem('token') != null && !token) {
        setToken(window.localStorage.getItem('token'));
    }

    useEffect(() => {
        dispatch(fetchAllPosts());
    }, []);
    useEffect(() => {
        dispatch(fetchAuthMe());
    }, []);

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/shop/:slug" element={<ProductCart />} />
                <Route path="/basket" element={<Basket />} />
                <Route path="/favourite" element={<Favourite />} />
                <Route path="/favourite" element={<Favourite />} />
                <Route path="/auth" element={<Auth setToken={setToken} token={token} />} />
                <Route path="/account" element={<Account setToken={setToken} role={role} />}>
                    <Route path='client' element={<Client />} />
                    <Route path='order' element={<Order role={role} />} />
                    <Route path='new-product' element={<NewEdit edit={false} />} />
                    <Route path='search' element={<Search />} />
                    <Route path='edit-product/:id' element={<NewEdit edit={true} />} />
                </Route>
                <Route path="/guarantee" element={<Guarantee />} />
                <Route path="/exchange" element={<Exchange />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/payment" element={<Payment />} />

                {/* New Routes for each shoe type */}
                <Route path="/botilioni" element={<Botilioni />} />
                <Route path="/botforti" element={<Botforti />} />
                <Route path="/krosivki" element={<Krosivki />} />
                <Route path="/mokasini" element={<Mokasini />} />
                <Route path="/napivchoboti" element={<Napivchoboti />} />
                <Route path="/sliponi" element={<Sliponi />} />
                <Route path="/tufli" element={<Tufli />} />
                <Route path="/chereviki" element={<Chereviki />} />
                <Route path="/choboti" element={<Choboti />} />
                <Route path="/shlopanci" element={<Shlopanci />} />

            </Routes>
        </div>
    );
}

export default App;