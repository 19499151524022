import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { format, intervalToDuration } from 'date-fns';
import './product.scss';

export const Product = ({ edit, data }) => {
    const [favourite, setFavourite] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [isSaleActive, setIsSaleActive] = useState(false);

    useEffect(() => {
        const favourites = JSON.parse(window.localStorage.getItem('favourite'));
        if (favourites) {
            const index = favourites.findIndex(fav => fav === data._id);
            if (index >= 0) {
                setFavourite(true);
            }
        }
    }, [data._id]);

    useEffect(() => {
        if (data && data.sale_start && data.sale_end) {
            const calculateTimeRemaining = () => {
                const saleEnd = new Date(data.sale_end);
                const now = new Date();

                if (saleEnd > now) {
                    const duration = intervalToDuration({ start: now, end: saleEnd});
                    setTimeRemaining({
                        days: duration.days,
                        hours: duration.hours,
                        minutes: duration.minutes,
                        seconds: duration.seconds,
                    });
                    setIsSaleActive(true);
                } else {
                    setTimeRemaining(null);
                    setIsSaleActive(false);
                }
            };

            calculateTimeRemaining();
            const intervalId = setInterval(calculateTimeRemaining, 1000);

            return () => clearInterval(intervalId);
        }
        setIsSaleActive(false);
    }, [data]);


   const addFavourite = () => {
       setFavourite(true);
        const favourites = JSON.parse(window.localStorage.getItem('favourite')) || [];
        favourites.push(data._id);
       window.localStorage.setItem('favourite', JSON.stringify(favourites));
    }

    const deleteFavourite = () => {
        const favourites = JSON.parse(window.localStorage.getItem('favourite'));
        const index = favourites.findIndex(fav => fav === data._id);
        favourites.splice(index, 1);
       window.localStorage.setItem('favourite', JSON.stringify(favourites));
        setFavourite(false);
    }


    const link = !edit ? `/shop/${data.slug}` : `/account/edit-product/${data._id}`;

    return (
        <div className={`product ${isSaleActive ? 'sale' : ''}`}>
            {data && (
                <>
                    {isSaleActive && <div className="sale-label">Розпродаж</div>} {/* Надпис "Акція" */}
                    <div className="image">
                        <Link to={link}>
                            <img
                                src={"https://api.shoedream.com.ua" + data.images[0]}
                                alt={data.title}
                                className="product__img"
                                loading="lazy"
                                onMouseOver={(e) =>
                                    (e.currentTarget.src =
                                        "https://api.shoedream.com.ua" + data.images[1])
                                }
                                onMouseOut={(e) =>
                                    (e.currentTarget.src =
                                        "https://api.shoedream.com.ua" + data.images[0])
                                }
                            />
                        </Link>
                    </div>
                    <div className="name">
                        <Link to={link}>
                            <span className="name_cat">{data.title}</span>
                        </Link>
                    </div>
                    <div className="brand">
                        <p>{data.brand}</p>
                    </div>
                    <div className="catalog_options">
                         <p>Доступні розміри:</p>
                         <span>{data.sizes.join(', ')}</span>
                    </div>
                    <div className="price">
                       <Link to={link}>
                             <span className="price-new">
                                  {data.discount ? data.new_price : data.price} грн
                              </span>
                            {data.discount && (
                                <span className="price-old">
                                       {data.price} грн
                                 </span>
                            )}
                        </Link>
                        {timeRemaining && (
                           <div className="sale-countdown">
                              {timeRemaining.days > 0 && <span>{timeRemaining.days} дн. </span>}
                               {timeRemaining.hours > 0 && <span>{timeRemaining.hours} год. </span>}
                               {timeRemaining.minutes > 0 && <span>{timeRemaining.minutes} хв. </span>}
                              <span>{timeRemaining.seconds} сек.</span>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};